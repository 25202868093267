<template>
  <div class="create-translations">

    <div class="translations-form">
      <!--  Form Content    -->
      <div class="form-content">
        <div class="mb-4 mr-2 bread-content">
          <span>2.Translations</span>
        </div>
        <!--    PAGE TOOLBAR    -->
        <ClPageToolbar @toggleEditorToolbar="handleToggleEditorToolbar" />
        <!--        TABS-->
        <CRow class="mb-4">
          <CCol col="12" sm="11" class="d-flex flex-wrap align-items-center">
            <h6 class="mr-2">Languages:</h6>
            <ul class="translation-tabs">
              <li v-for="(lang, index) in languages" :key="index" class="translation-tab-item">
                <button
                    class="translation-tab-button"
                    :class="{'translation-tab-button__active': currentLang !== lang}"
                    @click="handleTranslation(lang)">
                  <span
                      v-if="formData[lang].subject && (!messageData.body || formData[lang].body) && (!messageData.body || formData[lang].body)"
                      :class="{'check-icon__active': currentLang !== lang}"
                      class='exclaim check-icon'
                  >
                        <i class="fa fa-check"></i>
                      </span>
                  <span
                      v-else
                      :class="{'exclaim__active': currentLang !== lang}"
                      class="exclaim">&#33;</span>
                  <span :class="{'translation-tab-lang__active': currentLang !== lang}">{{ lang }}</span>
                </button>
              </li>
            </ul>
          </CCol>
        </CRow>
        <!--    Name    -->
        <h6 class="mb-2">Subject</h6>
        <CRow class="mb-3">
          <CCol col="12" md="5" class="text-left">
            <CInput
                v-model="messageData.subject"
                add-input-classes="col-sm-12"
                disabled="true"
            />
          </CCol>
          <CCol class="d-flex flex-column align-items-center text-center justify-content-center mb-2">
            <CIcon name="cil-arrow-right" class='check-icon' />
            <span>  to {{ currentLang }}</span>
          </CCol>
          <CCol col="12" md="5">
            <CInput
                :value="formData[currentLang].subject"
                @input="handleSubject"
                add-input-classes="col-sm-12"
            />
          </CCol>
        </CRow>
        <!--   Body     -->
        <h6 class="mb-2">Body</h6>
        <CRow>
          <CCol col="12" md="5">
            <ClEditor
                :disabled="true"
                :value-prop="messageData.body"
            />
          </CCol>
          <CCol class="d-flex flex-column align-items-center text-center justify-content-center mb-2">
            <CIcon name="cil-arrow-right" class='check-icon' />
            <span>  to {{ currentLang }}</span>
          </CCol>

          <CCol col="12" md="5">
            <ClEditor
                :is-toolbar="isToolbar"
                @change="handleBody"
                :value-prop="formData[currentLang].body"
            />
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import {getLanguagesData} from '@/utils/translationsUtils';
import {omit} from "lodash";
import ClPageToolbar from "@/shared/components/ClPageToolbar";
import ClEditor from "@/shared/components/formComponents/ClEditor";

export default {
  components: {
    ClEditor,
    ClPageToolbar
  },
  props: {
    messageData: Object,
    translationsData: Object
  },
  data() {
    return {
      currentLang: 'German',
      languages: ['German', 'Spanish', 'Italian', 'Russian'],
      subject: '',
      formData: {},
      translations: [],
      body: '',
      isToolbar: false
    }
  },
  computed: {
    isTranslated() {
      return this.getTranslatableFields()
          .map(item => !!this.formData[this.currentLang][item])
          .every(item => item === true);
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      const isFormData = Object.keys(this.translationsData).length;
      const langData = getLanguagesData(this.languages);
      this.formData = isFormData ? this.translationsData : langData;
    },

    getTranslatableFields() {
      const translatableFields = [];
      const {subject, body} = this.messageData;
      if (subject) translatableFields.push('subject');
      if (body) translatableFields.push('body');

      return translatableFields;
    },
    handleTranslation(lang) {
      this.currentLang = lang;
    },
    fieldsHandler(value, field) {
      if (!value) {
        this.formData[this.currentLang] = omit(this.formData[this.currentLang], field)
      } else {
        this.formData[this.currentLang] = {
          ...this.formData[this.currentLang],
          [field]: value.trim()
        }
      }
    },
    handleSubject(subject) {
      this.fieldsHandler(subject, 'subject');
    },
    handleDescription(description) {
      this.fieldsHandler(description, 'description');
    },
    handleBody(body) {
      this.fieldsHandler(body, 'body');
    },
    handleToggleEditorToolbar(val) {
      this.isToolbar = val;
    },
  },
  watch: {
    formData: {
      deep: true,
      handler(val) {
        this.$emit('updateTranslationsData', val)
      }
    },
  }
}
</script>

<style lang="scss">

.create-translations {
  height: 100%;

  .translations-form {
    height: calc(100% - 50px);

    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;
    }
  }
}
</style>